import React, {useContext} from "react";
import {
  BrowserRouter as ReactRouter,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import HomeRoute from "./routes/HomeRoute";

export const routes = {
  home: {
    path: "/",
    title: "EndlessFive"
  },
};

export const Router = ({navigation: Navigation}) => {


  return (
    <ReactRouter>
      <Switch>
        {/* Public routes: */}
        <Route path={routes.home.path}>
          <HomeRoute/>
        </Route>

        {/* Default route: */}
        <Redirect to={routes.home.path}/>
      </Switch>
    </ReactRouter>
  );
};
