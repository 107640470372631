import React, {useContext, Fragment} from "react";

import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  50% {
      opacity: 0;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  margin: 0;
  padding: 0;
  background-color: black;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  flex-direction: rows;
  align-items: stretch;
  justify-content: center;
`

const Page = styled('div')`
  background-color: white;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled('div')`
  flex: 0 0 75px;
  top: 100px;
  width: 75px;
  background-image: url('images/logo-black.png');
  background-size: contain;
  
  @media (max-width: 400px) {
    flex: 0 0 40px;
    top: 100px;
    width: 40px;
  }
`

const Divider = styled('div')`
  flex: .1;
`

const Slogan = styled('div')`

  @media (max-width: 360px) {
   font-size: 40px;
  }

  @media (min-width: 360px) and (max-width: 414px) {
   font-size: 50px;
  }
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 80px;
  font-family: Source Code Pro;
  font-weight: 900;
  padding: 40px;
  text-align: center;
`

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 30px;
  background-color: white;
  color: black;
  width: 100%;
  font-size: 12px;
  font-family: Source Code Pro;
  font-weight: 500;
  @media (max-width: 400px) {
   font-size: 10px;
  }
`

const HeaderItem = styled('div')`
  flex: 0 0 auto;
  padding-right: 12px;
`


const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 30px;
  background-color: white;
  color: black;
  width: 100%;
  font-size: 12px;
  font-family: Source Code Pro;
  font-weight: 500;
  
  @media (max-width: 400px) {
   font-size: 10px;
  }
`

const FooterLeft = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
`

const FooterRight = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 12px;
`

const ContactLink = styled('a')`
  text-decoration: none;
  color: black;
`

const HomeRoute = () => {

  return (
    <Wrapper>
      <Page>
        <Header>
          <HeaderItem><ContactLink href={"mailto:mcampbell@endlessfive.com"}>Contact</ContactLink></HeaderItem>
          <HeaderItem>Login</HeaderItem>
        </Header>
        <Divider>&nbsp;</Divider>
        <Logo>&nbsp;</Logo>
        <Slogan><div>Better content decisions with <span style={{color: "#64D799"}}>AI</span>.</div></Slogan>
        <Divider>&nbsp;</Divider>
        <Footer>
          <FooterLeft>Endless Five Technologies</FooterLeft>
          <FooterRight>©Endless Five, LLC</FooterRight>
        </Footer>
      </Page>
    </Wrapper>


  );
};

export default HomeRoute;
